// eslint-disable-next-line no-unused-vars
const progressive = require('./vendor/progressive-image.min');

document.addEventListener('DOMContentLoaded', () => {
  const loadingEl = document.querySelector('.loading-screen');
  loadingEl.classList.add('hidden');
});

// TODO: consider pivot to https://github.com/janpaepke/ScrollMagic

if (module.hot) {
  // N.b. self-accept the changed module for webpack to execute the new version
  module.hot.accept();
}
